import { defineStore } from "pinia";

export const usePageStore = defineStore("page", () => {
  const page = ref({});

  const header_theme = computed(() => {
    if (!page.value?.content) return "dark";
    if (page.value?.full_slug?.startsWith("resources/articles")) return "light";
    if (page.value?.full_slug?.startsWith("resources/guides")) return "light";
    if (page.value?.full_slug?.startsWith("resources/templates")) return "light";
    return page.value?.content?.header_theme || "dark";
  });

  const footer_theme = computed(() => {
    return page.value?.content?.footer_theme || "dark";
  });

  const updatePage = (newPage) => {
    page.value = newPage;
  };

  return { page, header_theme, footer_theme, updatePage };
});
